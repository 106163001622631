<template lang="pug">
    .main-wrapper.salvar-perguntas
        ProgressBar(v-if='waiting' mode="indeterminate")
        div(v-else)
            header.main-wrapper-header
                .p-grid.p-align-center
                    .p-col-3
                        Button.p-button-secondary.btn-back(icon='jam jam-chevrons-left' @click='$router.go(-1)')
                    .p-col-9.ta-right
                        h1.text-header.text-secondary MedClub /
                            router-link(:to='{ path: "/perguntas-frequentes" }') Perguntas Frequentes /
                            b {{ model.id ? 'Editar' : 'Adicionar' }}

            form(@submit.prevent='handleSubmit()')
                ProgressBar(v-if='waiting' mode="indeterminate")
                div(v-else)
                    Panel(:header="`${ model.id ? 'Editar' : 'Adicionar' } perguntas`")
                        .p-grid.p-fluid.p-align-end
                            .p-col-6
                                label.form-label Pergunta:
                                InputText.p-inputtext-lg(type='text' v-model="model.ds_pergunta" required)

                            .p-col-3
                                label.form-label Categoria:
                                ProgressBar(v-if='waitingCategoria' mode='indeterminate')
                                .p-inputgroup(v-else)
                                    Dropdown(
                                        v-model='model.cd_pergunta_categoria'
                                        optionLabel='nm_categoria'
                                        optionValue='id'
                                        dataKey='id'
                                        placeholder='- Selecione -'
                                        :filter='true'
                                        :options='options.categorias'
                                    )
                                    Button(icon="jam jam-plus" type="button"
                                        @click="dialogCriarCategoria=true"
                                        v-tooltip.top="'Cadastrar categoria'")
                            .p-col-3
                                label.form-label Tipo:
                                Dropdown( v-model='model.ie_tipo' :options='options.ie_tipo'
                                    dataKey='value' optionLabel='label' optionValue='value')

                            .p-col-9
                                label.form-label Resposta:
                                TextArea(type='text' v-model="model.ds_resposta" :rows="4" required)

                            .p-col-12.ta-right
                                ProgressSpinner.waitingSalvar(v-if='waitingSalvar' strokeWidth='6')
                                Button(v-else label='Salvar' type="submit" style='max-width:160px')

        Dialog.dialogCriarCategoria(
            header='Cadastrar categoria'
            @hide="dialogCriarCategoria_data={}"
            :visible.sync='dialogCriarCategoria'
            :modal='true')
            .p-fluid.my-4
                label.form-label Nome *:
                InputText(v-model='dialogCriarCategoria_data.nm_categoria')
            .ta-center.my-4
                ProgressSpinner.waitingCategoria(v-if='waitingCategoria' strokeWidth='6')
                Button.p-button(v-else label='Salvar' @click='criarCategoria()')

</template>

<style lang="scss">
.salvar-perguntas {
    .waitingSalvar {
        width: 25px;
        height: auto;
    }
}
.dialogCriarCategoria {
    width: 400px;
    max-width: 96%;
    .waitingCategoria {
        width: 25px;
        height: auto;
    }
}
</style>

<script>
import ProgressBar from "primevue/progressbar"
import Button from 'primevue/button'
import Dialog from 'primevue/dialog'
import Dropdown from 'primevue/dropdown'
import InputText from 'primevue/inputtext'
import TextArea from 'primevue/textarea'
import ProgressSpinner from 'primevue/progressspinner'
import Panel from 'primevue/panel'
import { PerguntasFrequentes, DominioValor } from './../../middleware'
import Tooltip from "primevue/tooltip"


export default {
    data() {
        return {
            waiting: false,
            waitingSalvar: false,
            waitingCategoria: false,
            model: {},
            dialogCriarCategoria: false,
            dialogCriarCategoria_data: {},
            options: {
                ie_tipo: [],
                categorias: []
            }
        }
    },
    components: { ProgressBar, Button, Dialog, Dropdown, InputText, ProgressSpinner, Panel, TextArea },
    directives: { tooltip: Tooltip },
    created() {
        this.waiting = true

        let id = 0
        if(this.$route.params.id !== undefined) {
            if (! isNaN(this.$route.params.id)) id = parseInt(this.$route.params.id)

            PerguntasFrequentes.findAll().then(response => {
                this.model = response.data.filter(duvida => duvida.id === id)[0]
                this.model.cd_pergunta_categoria = this.model.cd_pergunta_categoria?.id || null
                this.waiting = false
            })
        } else {
            this.waiting = false
        }
        this.getCategorias()
        this.getDominios()
    },
    methods: {
        handleSubmit() {
            let dataSend = Object.assign({}, this.model)
            const action = this.model.id ? 'edit' : 'save'

            if(!this.model.id) {
                dataSend.nr_ordem = 0
            }

            this.waitingSalvar = true
            PerguntasFrequentes[action](dataSend).then(response => {
                this.waitingSalvar = false
                if ([200,201].includes(response.status)) {
                    this.$toast.success("Salvo com sucesso.")
                } else if (response.data.detail) {
                    if (typeof response.data.detail == 'string')
                        this.$toast.error(response.data.detail, { duration: 3000 })
                    else response.data.detail.forEach(msg => this.$toast.error(msg, { duration: 3000 }))
                }
            })
        },
        getCategorias() {
            this.waitingCategoria = true
            PerguntasFrequentes.findAllCategorias({ie_status: true}).then(response => {
                this.waitingCategoria = false
                if (response.status === 200) {
                    this.options.categorias = response.data
                } else {
                    this.$toast.error("Não foi possível carregar as categorias.")
                }
            })
        },
        getDominios() {
                DominioValor.findAll({ds_mnemonico: ['CATEGORIA_PERGUNTA_FREQUENTE']}).then(response => {
                    if (response.status == 200){
                        response.data['CATEGORIA_PERGUNTA_FREQUENTE'].valores.forEach((un) => {
                            this.options.ie_tipo.push({ value: un.ie_valor, label: un.ds_valor})
                        })
                    }
                })
            },
        criarCategoria() {
            if (! this.dialogCriarCategoria_data.nm_categoria) {
                this.$toast.error("Campo nome é obrigatório.")
                return
            }

            this.waitingCategoria = true
            PerguntasFrequentes.saveCategoria(this.dialogCriarCategoria_data).then(response => {
                this.waitingCategoria = false
                if (response.status === 200) {
                    this.$toast.success("Categoria salva com sucesso.")
                    this.getCategorias()
                    this.dialogCriarCategoria = false
                } else if (response.data.detail) {
                    if (typeof response.data.detail == 'string')
                        this.$toast.error(response.data.detail, { duration: 3000 })
                    else response.data.detail.forEach(msg => this.$toast.error(msg, { duration: 3000 }))
                } else {
                    this.$toast.error("Não foi possível cadastrar essa categoria.")
                }
            })
        },
    }
}
</script>
